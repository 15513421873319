













import { defineComponent } from '@vue/composition-api'
import TitleTooltip from '@/components/TitleTooltip.vue'
import { useProjectStore } from '@/pinia/modules/project'
import { storeToRefs } from 'pinia'
import { ProjectStatus } from '@/api/project/model'

export default defineComponent({
    props: {
        name: String,
        info: {
            type: Object,
            default: () => {},
            required: false
        }
    },
    components: {
        TitleTooltip
    },
    setup() {
        const projectStore = useProjectStore()
        const { projectInfo } = storeToRefs(projectStore)
        return {
            ProjectStatus,
            projectInfo
        }
    }
})
