import { EntityType } from '@/api/project/model'
import { removeHTMLTag } from './common'
import i18n from '@/locale'

const textPoll = [EntityType.poll, EntityType.poll_multi, EntityType.onboarding_poll, EntityType.onboarding_poll_multi]
const imagePoll = [EntityType.picture_poll, EntityType.picture_poll_multi, EntityType.onboarding_poll_picture, EntityType.onboarding_poll_picture_multi]
const contentPoll = [EntityType.onboarding_ask_opinion, EntityType.speak,  EntityType.upload_video, EntityType.upload_mix]

export const verifyMessageEntity = (itemObj: any): any => {
    const { entity_type, options, link, branchs, parent_message_entity,test_group_id,ai_settings } = itemObj.message_entity
    let { content } = itemObj.message_entity
    content = removeHTMLTag(content)
    if (contentPoll.includes(entity_type)) {
        if (!content) {
            return i18n.t('entityType.rules.content')
        }
    } else if (textPoll.includes(entity_type)) {
        if (!content) {
            return i18n.t('entityType.rules.content')
        }
        const optionList: any[] = options.filter((v: any) => !v.is_system)
        const isEmpty = optionList.some((v) => !v.text)
        if (optionList.length < 2 || isEmpty) {
            return i18n.t('entityType.rules.options2')
        }
    } else if (imagePoll.includes(entity_type)) {
        if (!content) {
            return i18n.t('entityType.rules.content')
        }
        const optionList: any[] = options.filter((v: any) => !v.is_system)
        const isEmpty = optionList.some((v) => !v.text || !v.link)
        if (optionList.length < 2) {
            return i18n.t('entityType.rules.options1')
        }
        if (isEmpty) {
            return i18n.t('entityType.rules.imgOptions1')
        }
    } else if (entity_type === EntityType.rank) {
        if (!content) {
            return i18n.t('entityType.rules.content')
        }
        const optionList: any[] = options.filter((v: any) => !v.is_system)
        const isEmpty = optionList.some((v) => !v.text)
        if (optionList.length < 2 || isEmpty) {
            return i18n.t('entityType.rules.options2')
        }
    } else if (entity_type === EntityType.ask_experience) {
        if (!content) {
            return i18n.t('entityType.rules.content')
        }
        const optionList: any[] = options.filter((v: any) => !v.is_system)
        const isEmpty = optionList.some((v) => !v.text)
        if (optionList.length < 2 || isEmpty) {
            return i18n.t('entityType.rules.options2')
        }
       
    } else if (entity_type === EntityType.image) {
        // if (!link) {
        //      return i18n.t('entityType.rules.uploadImg')
        // }
        const optionList: any[] = options.filter((v: any) => !v.is_system)
        const isNameEmpty = optionList.some((v) => !v.text)
        if (optionList.length==0&&!link) {
            return i18n.t('entityType.rules.uploadImgArray')
        }
        if(isNameEmpty&&test_group_id!=0){
            return i18n.t('entityType.rules.uploadImgTextArray')
        }
        if(!content&&(test_group_id!=0&&!!test_group_id)){
            return i18n.t('entityType.rules.materials')
        }
    } else if (entity_type === EntityType.video) {
        const optionList: any[] = options.filter((v: any) => !v.is_system)
        const isNameEmpty = optionList.some((v) => !v.text)
        // if (!link) {
        //      return i18n.t('entityType.rules.uploadVideo')
        // }
        if (optionList.length==0&&!link) {
            return i18n.t('entityType.rules.uploadImgArray')
        }
        if(isNameEmpty&&test_group_id!=0){
            return i18n.t('entityType.rules.uploadImgTextArray')
        }
        if(!content&&(test_group_id!=0&&!!test_group_id)){
            return i18n.t('entityType.rules.materials')
        }
    } else if (entity_type === EntityType.branch) {
        if (branchs.length < 2) {
            return i18n.t('entityType.rules.branchOptions1')
        }
        if (branchs.some((v: any) => v.options.length === 0)) {
            return i18n.t('entityType.rules.branchOptions2')
        }
        if (branchs.some((v: any) => !removeHTMLTag(v.content))) {
            return i18n.t('entityType.rules.branchContent')
        }
        const branchOptions: any[] = []
        branchs.forEach((item: any) => {
            branchOptions.push(...item.options)
        })
        if (branchOptions.length < parent_message_entity?.options.length) {
            return i18n.t('entityType.rules.branchAll')
        }
        if(ai_settings.ai_enable&&ai_settings.user_prompt==''){
            return i18n.t('projectSurvey.AIAnalyze.errorMessage')
         }
    }else if(entity_type===EntityType.ask_opinion){
        if (!content) {
            return i18n.t('entityType.rules.content')
        }
        if(ai_settings.ai_enable&&ai_settings.user_prompt==''){
           return i18n.t('projectSurvey.AIAnalyze.errorMessage')
        }
    }else if(entity_type===EntityType.upload_image){
        if (!content) {
            return i18n.t('entityType.rules.content')
        }
        if(ai_settings.ai_enable&&ai_settings.theme==''){
           return i18n.t('projectSurvey.AIAnalyze.errorMessage3')
        }
    }
    return ''
}
