
























































import { deleteTestGroupApi } from '@/api/project'
import { computed, defineComponent, reactive, Ref, toRef } from '@vue/composition-api'
import { useProjectStore } from '@/pinia/modules/project'

export default defineComponent({
    props: {
        isDisabledForm:  {
            type: Boolean,
            default: false
        }
    },
    setup(props, { root, emit }) {
        const projectStore = useProjectStore()
        const { testGroups } = projectStore
        const projectUuId = computed(() => root.$route.params.projectUuId)

        const testGroupsList: Ref<any[]> = toRef(testGroups, projectUuId.value)
        projectStore.getProjectTestGroups(projectUuId.value)

        const onDeleteGroup = (item: any, index: number) => {
            root.$msgbox({
                showCancelButton: true,
                message: <string>root.$i18n.t('confirmDeleteMsg')
            })
                .then(async () => {

                    await deleteTestGroupApi({
                        uuid: projectUuId.value,
                        sectionId: item.id
                    })
                    testGroupsList.value.splice(index, 1)
                    root.$message.success(root.$t('deleteSuccess').toString())
                })
                .catch()
        }

        const testGroupObj = reactive({
            edit: (item: any) => {
                emit('edit', JSON.parse(JSON.stringify(item)))
            },
            add: () => {
                emit('add')
            }
        })
        return {
            testGroupsList,
            onDeleteGroup,
            testGroupObj
        }
    }
})
