














import { computed, defineComponent, reactive, ref, set } from '@vue/composition-api'
import { getProjectApi ,testGroupsApi} from '@/api/project'
import { showError } from '@/utils/common'
import ProjectHeader from './components/ProjectHeader.vue'
import ProjectTestGroupList from './components/projectTestGroup/ProjectTestGroupList.vue'
import ProjectTestGroupEdit from './components/projectTestGroup/ProjectTestGroupEdit.vue'
import { useProjectStore } from '@/pinia/modules/project'
import {  ProjectStatus } from '@/api/project/model'
export default defineComponent({
    components: {
        ProjectHeader,
        ProjectTestGroupList,
        ProjectTestGroupEdit
    },
    setup(props, { root }) {
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const detailObj: any = reactive({})
        const projectStore = useProjectStore()
        const isDisabledForm = ref(false)
        const getProject = async () => {
            try {
                const { details,status } = await getProjectApi({
                    uuid: projectUuId.value
                })
                isDisabledForm.value = ![ProjectStatus.Published, ProjectStatus.Start, ProjectStatus.End].includes(status)
                Object.keys(details).forEach((key) => {
                    set(detailObj, key, details[key])
                })
            } catch (error) {
                showError(error)
            }
        }
        getProject()
        projectStore.updateProjectTestGroup(projectUuId.value)

        const ProjectTestGroupListRef = ref()
        const ProjectTestGroupEditRef = ref()
        const testGroupObj = reactive({
            edit: (item: any) => {
                ProjectTestGroupEditRef.value.testGroupObj.edit(item)
            },
            add: () => {
                ProjectTestGroupEditRef.value.testGroupObj.add()
            },
            cancel: () => {},
            update: () => {
                console.log(`update getSegments`)
            }
        })
        return {
            projectUuId,
            detailObj,
            testGroupObj,
            ProjectTestGroupListRef,
            ProjectTestGroupEditRef,
            isDisabledForm
        }
    }
})
