




























































import { testGroupsMaterialsApi,getProjectQuotaOnboardingPollApi,updateOrCreateTestGroupApi,computeQuotaGroupApi } from '@/api/project'
import { EntityType, UpdateOrCreateTestGroupDto,ComputeQuotaGroupDto } from '@/api/project/model'
import { showError } from '@/utils/common'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { computed, defineComponent, reactive ,ref } from '@vue/composition-api'
import { useProjectStore } from '@/pinia/modules/project'
import { verifyMessageEntity } from '@/utils/verify-message-entity'
export default defineComponent({
    components: {
        ErrorMessage
    },
    props: {
        isDisabledForm:  {
            type: Boolean,
            default: false
        }
    },
    setup(props, { root, emit }) {
       
        const errorMessage = ref(root.$i18n.t('projectTestGroup.errorMsg'))
        const errorQuotaMessage=ref(root.$i18n.t('projectTestGroup.errorQuotaMessage'))
        const projectStore = useProjectStore()
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const quotaList:any = ref([])
        const getProjectQuotaOnboardingPoll = async () => {
            try {
                const list = await getProjectQuotaOnboardingPollApi({
                    uuid: projectUuId.value
                })
                list.forEach((item: any) => {
                    item.options.forEach((obj: any) => {
                        obj.rate = undefined
                        obj.rateCopy = undefined
                        obj.limit_number = undefined
                    })
                    if ([EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(item.entity_type)) {
                        if (
                            !verifyMessageEntity({
                                message_entity: item
                            })
                        ) {
                            quotaList.value.push(item)
                        }
                    }
                })
            } catch (error) {
                showError(error)
            }
        }
        
        const material_id: any = ref('')
        const editType: any = ref('add')
        const materials:any = ref([])
        const materialsList:any = ref([])
        const getMaterials = async () => {
            materials.value = []
            materialsList.value=[]
            try {
                const list = await testGroupsMaterialsApi({
                    uuid: projectUuId.value
                })
                list.forEach((item: any) => {
                    materialsList.value.push(item)
                    if(item.test_group_id ==0){
                        materials.value.push(item)
                    }

                })
            } catch (error) {
                showError(error)
            }
        }

        const computeQuotaGroup = async () => {
            try {
                const testGroup: ComputeQuotaGroupDto = {
                    uuid: projectUuId.value,
                    id: testGroupObj.curtestGroupObj.id,
                    quotas: testGroupObj.curtestGroupObj.itemObj.map((item: any) => {
                        return {
                            id: 0,
                            project_uuid:  projectUuId.value,
                            message_entity_id: Number(item.id),
                            test_group_id: testGroupObj.curtestGroupObj.id,
                        }
                    })
                }
                if(testGroup.quotas.length==0){
                    testGroupObj.isShowQuotaErrorMsg=false
                }else{
                    const data = await computeQuotaGroupApi(testGroup)
                    testGroupObj.isShowQuotaErrorMsg=data.QuotaGroupSize<30
                }
               
            } catch (error) {
                showError(error)
            }
        }

        const testGroupObj:any = reactive({
            curtestGroupObj: undefined as any,
            submitLoad: false,
            rounds:0,
            updateOrCreateTestGroup: async () => {
                const testGroup: UpdateOrCreateTestGroupDto = {
                    project_uuid: projectUuId.value,
                    id: testGroupObj.curtestGroupObj.id,
                    name: testGroupObj.curtestGroupObj.name,
                    material_id:material_id.value,
                    rounds: Number(testGroupObj.curtestGroupObj.rounds),
                    quotas: testGroupObj.curtestGroupObj.itemObj.map((item: any) => {
                        return {
                            id: 0,
                            project_uuid:  projectUuId.value,
                            message_entity_id: Number(item.id),
                            test_group_id: testGroupObj.curtestGroupObj.id,
                        }
                    })
                }
                testGroupObj.submitLoad = true
                try {
                    const data = await updateOrCreateTestGroupApi(testGroup)
                    root.$message.success(root.$t('saveSuccess').toString())
                    testGroupObj.curtestGroupObj = null
                    projectStore.updateProjectTestGroup(projectUuId.value)
                } catch (error) {
                    showError(error)
                }
                testGroupObj.submitLoad = false
                emit('update')
            },
            isShowErrorMsg:computed(() => {
                let options =materials.value.filter((item: any) => item.id==material_id.value)
                if(options.length==0){
                    return false
                }else{
                    return testGroupObj.curtestGroupObj.rounds>options[0].options.length
                }
            }),
            isShowQuotaErrorMsg:false,
            onQuotasChange: (val: any) => {
                testGroupObj.curtestGroupObj.itemObj= quotaList.value.filter((quota:any)=>{return val.some((item:any)=>item==quota.id)})
                computeQuotaGroup()
            },
            onMaterialChange: (val: any) => {
                let options =materials.value.filter((item: any) => item.id==material_id.value)
                if(options.length==0){
                    testGroupObj.rounds=0
                }else{
                    testGroupObj.rounds=options[0].options.length
                }
            },
            isDisabled: computed(() => {
                return Boolean(testGroupObj.curtestGroupObj.name)&&Boolean(testGroupObj.curtestGroupObj.rounds)&&Boolean(material_id.value)
            }),
            edit:async (item: any) => {
                materials.value=[]
                materialsList.value=[]
                quotaList.value=[]
                material_id.value=''
                testGroupObj.rounds=0
                testGroupObj.isShowQuotaErrorMsg=false
                try {
                    const testGroups = await testGroupsMaterialsApi({
                        uuid: projectUuId.value
                    })
                    testGroups.forEach((test: any) => {
                        materialsList.value.push(test)
                        if(test.test_group_id ==0||item.id==test.test_group_id){
                            materials.value.push(test)
                        }

                    })
                    const list = await getProjectQuotaOnboardingPollApi({
                        uuid: projectUuId.value
                    })
                    list.forEach((item: any) => {
                        item.options.forEach((obj: any) => {
                            obj.rate = undefined
                            obj.rateCopy = undefined
                            obj.limit_number = undefined
                        })
                        if ([EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(item.entity_type)) {
                            if (
                                !verifyMessageEntity({
                                    message_entity: item
                                })
                            ) {
                                quotaList.value.push(item)
                            }
                        }
                    })
                    editType.value = 'edit'
                    testGroupObj.curtestGroupObj=item
                    material_id.value=item.material?item.material.id:''
                    testGroupObj.curtestGroupObj.itemObj=item.quotas
                    testGroupObj.curtestGroupObj.quotas=item.quotas.map((quota:any)=>quota.message_entity_id)
                    testGroupObj.curtestGroupObj.itemObj= quotaList.value.filter((quota:any)=>{return testGroupObj.curtestGroupObj.quotas.some((item:any)=>item==quota.id)})
                    testGroupObj.onMaterialChange()
                    computeQuotaGroup()
                } catch (error) {
                    showError(error)
                }
               
            },
            delete: (item: any) => {
                computeQuotaGroup()
            },
            add: () => {
                initData()
                editType.value = 'add'
                testGroupObj.curtestGroupObj = {
                    name: '',
                    id:0,
                    quotas:[],
                    itemObj:[],
                    material_id: '',
                    rounds: null
                }
               
            },
            cancel: () => {
                testGroupObj.curtestGroupObj = null
                emit('cancel')
            }
        })


        const initData = async () => {
            materials.value=[]
            materialsList.value=[]
            quotaList.value=[]
            material_id.value=''
            testGroupObj.rounds=0
            testGroupObj.isShowQuotaErrorMsg=false
            getProjectQuotaOnboardingPoll()
            getMaterials()
        }
        initData()
       
        return {
            editType,
            material_id,
            quotaList,
            materials,
            testGroupObj,
            errorMessage,
            errorQuotaMessage
        }
    }
})
